<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">CLIENTS</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <div class="flex justify-space-between mv-10">
        <div class="box left center">
          <el-input placeholder="Search"
                    size="small"
                    ref="search"
                    v-model="search"
                    style="width: 200px;"
                    @input="handleSearchInput"
                    class="box center"></el-input>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          :current-page.sync="currentPage"
          background
          :pager-count="5"
          layout="total, sizes, prev, pager, next"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="pageSize"
          :total="filtered.length">
        </el-pagination>
        <div class="box right center">
          <!-- <el-button @click="addRow()" size="small">
              Add Row
          </el-button> -->
          <!-- <el-button size="small">
              Create new Client
          </el-button> -->
        </div>
    </div>
    <div :style="{width: tableWidth + 'px'}">
      <el-table
        :max-height="tableHeight"
        @filter-change="handleFilterChange"
        :data="paginated">
        <!-- <el-table-column
          prop="uid"
          width="80"
          label="Id">
        </el-table-column> -->
        <el-table-column
          prop="fullName"
          width="180"
          label="First name">
          <template #default="scope">
            <div :class="[scope.row.hasOwnProperty('isChild') && scope.row.isChild ? 'pl-15 success-light-text' : 'info-text']">
              {{scope.row.fullName}}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="surname"
          width="120"
          label="Surname">
        </el-table-column> -->
        <el-table-column
          width="220"
          prop="email"
          label="Email">
        </el-table-column>
        <el-table-column
          width="180"
          prop="phone"
          label="Phone">
        </el-table-column>
        <el-table-column
          width="100"
          prop="birthday"
          label="Birthday">
          <template #default="scope">
            <div>
              {{formatDate(scope.row.birthday)}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="180"
          prop="group"
          column-key="group"
          :filters="groupsForFilter"
          :filter-method="filterGroupsMethod"
          filter-placement="bottom-end"
          label="Group">
          <template #default="scope">
            <div v-if="scope.row.hasOwnProperty('group') &&
              scope.row.group.hasOwnProperty('name')">
              {{scope.row.group.name}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          prop="subscription"
          label="Subs">
        </el-table-column>
        <el-table-column
          width="80"
          prop="lesson"
          label="Lesson">
        </el-table-column>
        <el-table-column
          width="200"
          prop="comment"
          label="Comment">
        </el-table-column>
        <el-table-column
          width="100"
          prop="dateReg"
          label="Registration">
          <template #default="scope">
            <div v-if="scope.row.hasOwnProperty('dateReg')">
              {{formatDate(scope.row.dateReg)}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="QR">
          <template #default="scope">
            <el-button type="text" size="small">
              <a :href="'https://customer.rolldxb.com/qr/' + scope.row._id" target="_blank" rel="noopener noreferrer">Link</a>
            </el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="Reg Date">
          <template #default="scope">
            {{formatDate(scope.row.regDate)}}
          </template>
        </el-table-column> -->
        <el-table-column
          width="120">
          <template slot-scope="scope">
              <div class="flex gaps">
                  <el-button @click.native.prevent="editRow(scope.row)" type="text" size="small">
                      Edit
                  </el-button>
              </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Clients',

  data () {
    return {
      search: '',
      dataClients: [],
      currentPage: 1,
      pageSize: 10,
      filtersGroup: []
    }
  },

  computed: {
    ...mapState({
      innerHeight: state => state.nav.innerHeight,
      innerWidth: state => state.nav.innerWidth,
      members: state => state.members.members
    }),
    ...mapGetters([
      'groupsForSelect',
      'groupsForFilter'
    ]),
    tableHeight () {
      return this.innerHeight - 181
    },
    tableWidth () {
      return this.innerWidth - 30
    },
    filterAll () {
      return this.dataClients
        .filter(c => {
          let groupName = c.hasOwnProperty('group') &&
            c.group.hasOwnProperty('name') ? c.group.name : ''
          return this.filtersGroup.length ? this.filtersGroup.includes(groupName) : true
        })
    },
    filtered () {
      return this.filterAll.filter(item => {
        return this.search.length < 2 ||
       (item.hasOwnProperty('first') && item.first.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
       (item.hasOwnProperty('surname') && item.surname.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
       (item.hasOwnProperty('phone') && item.phone.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
       (item.hasOwnProperty('email') && item.email.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
      })
    },
    paginated () {
      return this.filtered.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    }
  },

  methods: {
    handleFilterChange (filters) {
      if (filters.hasOwnProperty('group')) {
        this.filtersGroup = filters.group
      }
    },
    formatDate (date) {
      return new Date(new Date(date).toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10)
      // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
      // return date !== undefined ? date.toLocaleString('en-GB', options) : ''
    },
    handleSizeChange (val) {
      this.pageSize = val
      localStorage.setItem('pageSize', val)
    },
    handleSearchInput () {},
    filterGroupsMethod (value, row) {
      let name = row.hasOwnProperty('group') &&
        row.group.hasOwnProperty('name') ? row.group.name : ''
      return name === value
    },
    // deleteRow (row, index) {
    //   this.$store.dispatch('removeMember', row._id)
    //     .then(() => {
    //       this.dataClients.splice(index, 1)
    //     })
    // },
    editRow (row) {
      let id = row.hasOwnProperty('isChild') && row.isChild ? row.parentId : row._id
      this.$onCommandParams('editClient', { id })
    }
  },
  created () {
    if (localStorage.getItem('pageSize') !== null) {
      this.pageSize = Number(localStorage.getItem('pageSize'))
    }
  },
  mounted () {
    this.dataClients = this.members.map((x) => Object.assign({}, x))
  }
}
</script>
